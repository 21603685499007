/**
 * Nav component
 */

import React, { useState } from "react"
import { Flex, Box, Button, Image, Text } from "rebass"
import { Input } from '@rebass/forms'
import { Link } from "gatsby"
import { SearchItem, Modal } from "."
import logo from "../../content/assets/logo-yabuko.svg"
import { IoMdSearch } from 'react-icons/io'
import { GrFormClose } from 'react-icons/gr'

const Nav = ({sticky, posts}) => {
  const [openSearch, setOpenSearch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState(null);
  const toggleSearch = () => { setOpenSearch(!openSearch) }
  const toggleModal = () => { setOpenModal(!openModal) }
  const filterBy = (value) => {
    if (!value) setFilteredPosts(null)
    else setFilteredPosts(posts.filter(({node}) => node.frontmatter.title.toLowerCase().includes(value.toLowerCase())))
  }
  return (
    <div>
      <Flex
      width={1}
      className={`navbar ${sticky ? 'navbar-sticky' : null }`}
      height={70}
      px={2}
      color='black'
      alignItems='center'
      justifyContent='space-between'
      >
        <Box width={['65px', 1/4, 1/4]}>
          {/* <Button><Image height={20} src="https://icon.now.sh/burger" alt="Menu"/></Button> */}
        </Box>
        <Flex width={['auto', 1/2, 1/2]} textAlign='center' justifyContent='center' alignItems='center'>
          <Link to='/' className='logo' aria-label='Yabuko'>
            <Image alt='Yabuko' src={logo} height='45px'/>
          </Link>
        </Flex>
        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} width={['65px', 1/4, 1/4]} textAlign='right'>
          { sticky ? 
          <Button mx={[0, 10]} aria-label="Search">
            <Flex alignItems={'center'} flexDirection={'column'} onClick={toggleSearch} fontSize={25}>
              <IoMdSearch/>
            </Flex>
          </Button>
          :
          null
          }
          <Button
          onClick={toggleModal}
          sx={{
          '@media screen and (max-width: 64em)': {
            display: 'none',
          },
          // '@media print': {
          //   display: 'none',
          // }
          }} 
          className="subscribe-navbar"
          letterSpacing={3}
          variant='primary' 
          px={20}
          py={9}
          fontWeight={700}
          >
            subscribe
          </Button>
        </Flex>
      </Flex>
      { openSearch ? 
        <Flex className="search" flexDirection={'column'} alignItems={'center'} py={100}>
          <Flex width={1} alignItems={'center'} justifyContent={'space-between'} height={60} mb={20}>
            <Box width={1/3}/>
            <Input width={[1, 1/3]} onKeyUp={(e) => filterBy(e.target.value)}/>
            <Box width={1/3} textAlign={'right'}>
              <Button onClick={toggleSearch} mr={20} height={[40, 50]} p={0} fontSize={35}><GrFormClose/></Button>        
            </Box>          
          </Flex>
          {
            filteredPosts ? null : 
            <Flex flexDirection={'column'}>
              <Text as='p' className="uppercase" letterSpacing={5}>Explore</Text>
            </Flex>
          }
          <Box
            minHeight={'65vh'}
            height={300}
            overflowY={'auto'}
            sx={{
            display: 'grid',
            gridGap: 1,
            gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
            '@media screen and (max-width: 64em)': {
              gridTemplateColumns: 'repeat(1, minmax(128px, 1fr))',
            }
          }}>
            {
              filteredPosts ?
              filteredPosts.map(({node}) => {
                return (
                  <SearchItem key={`${node.id}`} post={node}></SearchItem>
                )
              })
              :
              null
            }
          </Box>
        </Flex>
        :
        null
      }
      {
        openModal ?
        <Modal toggleModal={toggleModal}/>
        :
        null
      }
    </div>
  )
}

export default Nav