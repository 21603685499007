/**
 * Subscribe Form
 */

import React, { useState } from "react"
import { Flex, Text, Box } from "rebass"
import { Input } from '@rebass/forms'
import { AiOutlineRight } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import addToMailchimp from 'gatsby-plugin-mailchimp'

const SubscribeFormVariant = ({variant, ...rest}) => {
    const [email, setEmail] = useState(undefined)
    const [result, setResult] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const _handleSubmit = async (e, email) => {
        setLoading(true)
        e.preventDefault();
        const result = await addToMailchimp(email)
        if (result.result === 'success') {
            result.msg = 'Thank you for registering'
            // setType('success')
        }
        else if (result.msg.includes('already')) {
            result.msg = 'Already registered'
            // setType('error')
        }
        else {
            result.msg = 'Email not valid'
            // setType('error')
        }
        setResult(result)
        setLoading(false)
    }
    const _handleChange = (e) => {
        setEmail(e.target.value)
    }
    return (
        <Flex {...rest} flexDirection='column'>
            <Flex as="form" onSubmit={(e) => _handleSubmit(e, email)} justifyContent='center' height={50}>
                <Input
                    onChange={_handleChange}
                    className={`subscribe-form-${variant}`}
                    id='email'
                    type='email'
                    aria-label="email"
                    placeholder='Enter your email to subscribe'
                />
                <Flex as="button"
                    aria-label="subscribe"
                    className={`subscribe-form-button subscribe-form-${variant}`}
                    letterSpacing={3}
                    px={15}
                    disabled={loading}
                    alignItems="center"
                >
                    { loading ? 
                    <ClipLoader size={20} color='inherit'/>
                    :
                    <AiOutlineRight fontSize={20}/>
                    }
                </Flex>
            </Flex>
            <Box textAlign='center' height={30}>
                { result ?
                    <Text as="span" className={`subscribe-msg-${variant}`}>{result.msg}</Text>
                    :
                    null             
                }
            </Box>
        </Flex>
    )
}

export default SubscribeFormVariant