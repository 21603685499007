/**
 * Main Card component for the main post
 */

import React, { useState, useEffect } from "react"
import { Flex, Text } from "rebass"
import { Link } from "gatsby"
import { optimizeImg } from "@utils"

const MainCard = ({post, id}) => {

    const [bg, setBg] = useState(null)
    
    const backgroundObject = bg ?
    {
        // background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 62%, rgba(0, 0, 0, 0.50) 100%), url(${bg}) no-repeat`,
        background: `url(${bg}) no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    : null
    
    useEffect(() => {
        setBg(id === 0 ?
        optimizeImg('height', '125vh', post.frontmatter.image)
        :
        optimizeImg('height', '75vh', post.frontmatter.image)
        )
    }, [id, post.frontmatter.image])

    const postStyle = id === 0 ? 
    {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: 3,
    height: '125vh',
    paddingBottom: '25px',
    } 
    : null

    const postStyleMedia = id === 0 ?
    {
        '@media screen and (max-width: 52em)': {
            gridColumnStart: 0,
            gridColumnEnd: 3,
            gridRowStart: 0,
            gridRowEnd: 3,
        },
        '@media screen and (max-width: 40em)': {
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: 1,
            gridRowEnd: 1,
            height: '100vh',
        }
    } : null
 
    return (
        <>
        <Flex as='article'
        sx={{
            paddingBottom: '15px',
            ...postStyle,
            ...backgroundObject,
            '@media screen and (max-width: 40em)': {
                ...postStyleMedia,
                height: id === 0 ? '100vh' : '50vh',
            },
            '@media screen and (max-width: 52em)': {
                ...postStyleMedia,
                height: '50vh',
            }
        }}
        alignItems='center'
        justifyContent='flex-end'
        flexDirection='column'
        bg='white'
        height={id === 0 ? 'auto' : 'calc(100vh / 1.5)'}
        >
                {/* <Flex justifyContent='center' py={ id === 0 ? '15px' : '10px' } pb={ id === 0 ? null : '5px'}>
                    <Separator size={ id === 0 ? 'long' : 'short' } color='black'/>
                </Flex> */}
            {/* <Flex mt={15}>
                <Link className='uppercase category' style={{marginTop: '20px'}} to={`categories/${post.frontmatter.category}`}>
                    <Text as="span" fontSize={ id === 0 ? '20px' : '15px' }>{post.frontmatter.category}</Text>
                </Link>
            </Flex> */}
            <Flex flexDirection="column" width={id === 0 ? .90 : 0.90} textAlign='center' mx='auto'>
                <Link to={`${post.fields.slug}`}>
                    <Text as='h2' className='uppercase capitalize item-link highlighted' letterSpacing={5} fontSize={ id === 0 ? 40 : 30 }>{post.frontmatter.title}</Text>
                </Link>
                <Link className='uppercase category' style={{marginTop: '10px'}} to={`categories/${post.frontmatter.category}`}>
                    <Text as="span" fontSize={ id === 0 ? '20px' : '15px' }>{post.frontmatter.category}</Text>
                </Link>
            </Flex>
        </Flex>
        </>
  )
}

export default MainCard