/**
 * Grid component
 */

import React from "react"
import { Box } from "rebass"

const Grid = ({gap, columns, props, children, ...rest}) => {
    return (
        <Box
            {...rest}
            sx={{
                display: 'grid',
                gridGap: gap[0] ? gap[0] : gap,
                gridTemplateColumns: `repeat(${columns[0]}, minmax(100px, 1fr))`,
                '@media screen and (max-width: 52em)': {
                    gridTemplateColumns: `repeat(${columns[2]}, minmax(100px, 1fr))`,
                    gridGap: gap[2] ? gap[2] : gap
                },
                '@media screen and (max-width: 40em)': {
                    gridTemplateColumns: `repeat(${columns[1]}, minmax(100px, 1fr))`,
                    gridGap: gap[1] ? gap[1] : gap
                }
            }}>
            {children}
        </Box>
    )
}

export default Grid