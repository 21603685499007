/**
 * Modal component
 */

import React from "react"
import { Flex, Box, Button, Text } from "rebass"
import { SubscribeFormVariant } from "./index"
import { CldImage } from "@extend"
import { GrFormClose } from 'react-icons/gr'

const Modal = ({toggleModal}) => {
    return (
        <Flex
        className='modal'
        sx={{
            width: '100%',
            backgroundColor: '#ffffffcf',
        }}
        height='100vh' alignItems='center' justifyContent='center'
        >
            <Flex sx={{ position: 'relative' }} className='modal-box' flexDirection='column' justifyContent='center' alignItems='center' width={[0.9, 1/2, 1/2]} height='75vh' bg='white'>
                <Box sx={{ position: 'absolute', top: '0px', right: '0px' }}>
                    <Button onClick={toggleModal} height={30} p={10} fontSize={35}><GrFormClose/></Button>        
                </Box>
                <Text as='h2' textAlign='center' my={3}>Join us!</Text>
                <CldImage alt="Join us" height={'40%'} mb={3} src='https://res.cloudinary.com/yabuko/image/upload/v1588350498/website/20200501_172536_ndvg9h.jpg' transformations={['e_blackwhite']}/>
                {/* <Text as='p' textAlign='center' width={.8} mb={20}>
                    You will be notified each time we publish something new</Text> */}
                <SubscribeFormVariant width={.6} variant='dark'/>
                <Text as='span' color='#353535'>We respect your privacy</Text>
            </Flex> 
        </Flex>
    )
}

export default Modal