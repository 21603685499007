import React from "react"
import Grid from "./extend/Grid"
import { MainCard } from "."

const Main = ({posts}) =>  {
    const postsInIndex = posts.slice(0, 6)
    return (
        <Grid
            as="section"
            mt={20}
            gap={3}
            columns={[3, 1, 2]}
        >
            {
                postsInIndex ?
                postsInIndex.map(({node}, idx) => {
                    return (
                        <MainCard key={node.id} id={idx} post={node}/>
                    )
                })
                :
                null
            }            
        </Grid>
    )
}

export default Main


