import { window } from 'browser-monads';

const capitalizeString = (string) => {
    return string
    .split(' ')
    .map(word => word[0]
    .toUpperCase() + word
    .slice(1))
    .join(' ')
}

const minutesFormatter = (value) => {
    const hours = Math.floor(value / 60)
    const minutes = value % 60
    return `${hours} ${hours > 1 ? 'hrs' : 'hr'} ${minutes > 0 ? `${minutes} mins` : ''}`
}
const roundHundreds = (number) => {
    return Math.round(number/100)*100
}

const convertViewportToPixels = (value) => {
    const valueToNumber = Number(value.replace(/[^0-9]+/, ''))
    if (value.includes('vh')) return window.screen.height * (valueToNumber/100)
    if (value.includes('vw')) return window.screen.width * (valueToNumber/100)
}

const optimizeHeroImg = (imgUrl) => {
    const size = window.screen.width > window.screen.height ? `w_${roundHundreds(window.screen.width)}` : `h_${roundHundreds(window.screen.height)}`
    const optimizedUrl = imgUrl.replace('upload/', `upload/c_scale,${size},f_auto,q_60/`)
    return optimizedUrl
}

const optimizeImg = (param, resizeTo, imgUrl) => {
    let pixels = resizeTo
    if (typeof resizeTo === 'string') {
        pixels = convertViewportToPixels(pixels)
    }
    const size = param === 'height' ? `h_${roundHundreds(pixels)}` : `w_${roundHundreds(pixels)}`
    return imgUrl.replace('upload/', `upload/c_scale,${size},f_auto,q_60/`)
}

export { capitalizeString, minutesFormatter, optimizeHeroImg, optimizeImg, roundHundreds }