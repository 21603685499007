import React from "react"

const Separator = ({size, color, type}) => {  
  return (
    type === 'curly' ?    
    <div className={`holder separator-${size}`}>
        <div className={`smallLine line1-${color}`}></div>
        <div className={`smallLine line2-${color}`}></div>
    </div>
    :
    <div className={`separator-line-${size}-${color}`}></div>
  )
}

export default Separator