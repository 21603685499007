/**
 * Footer
 */

import React from "react"
import { Link } from "gatsby"
import { Flex, Box, Text, Image } from "rebass"
import { SubscribeFormVariant } from "@components"
import logo from "../../../content/assets/logo-yabuko.svg"
import { GrInstagram, GrTwitter, GrFacebookOption } from 'react-icons/gr';

const Footer = () => {
  return (
    <Box as="footer"
    color='black'
    bg='#fafafa'
    py={30}
    >
        <Box
        as="section"
        className="footer-content"
        sx={{
        display: 'grid',
        gridGap: 0,
        gridTemplateColumns: 'repeat(2, minmax(128px, 1fr))',
        '@media screen and (max-width: 64em)': {
            gridTemplateColumns: 'repeat(1, minmax(128px, 1fr))',
        }
        }}>
            <Flex as="header" flexDirection="column" alignItems="center" textAlign={'center'} px={50}>
                <Text as="p" pb={10} color="black">Enjoying our content? <br/>Subscribe now!</Text>
                <SubscribeFormVariant 
                variant={'dark'}
                width={.7}
                sx={{
                    '@media screen and (max-width: 52em)': {
                        width: '60%'
                    },
                    '@media screen and (max-width: 40em)': {
                        width: '100%',
                    }
                }}
                />
            </Flex>
            <Flex as="nav" justifyContent={'center'} flexDirection={'column'} textAlign={'center'} px={50}>
                <Link to={'categories'} className="footer-link" title="Categories">Categories</Link>
                <Link to={'about'} className="footer-link" title="Who we are">Who we are</Link>
                <Link to={'contact'} className="footer-link" title="Contact">Contact</Link>
                <Link to={'collaborate'} className="footer-link" title="Collaborate">Collaborate</Link>        
            </Flex>
        </Box>        
        <Flex
        as="section"
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        >
            <Box py={30}>
                <a className="social-link" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/WeAreYabuko"><GrFacebookOption style={{fontSize: '25px', color: '#353535', marginRight: '10px'}}/></a>
                <a className="social-link" target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/WeAreYabuko"><GrTwitter style={{fontSize: '25px', color: '#353535', marginRight: '10px'}}/></a>
                <a className="social-link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/kaja.eliza"><GrInstagram style={{fontSize: '25px', color: '#353535', marginRight: '10px'}}/></a>
            </Box>
            <Link to='/' className='logo' aria-label='Yabuko'>
                <Image alt='Yabuko' src={logo} height='45px'></Image>
            </Link>
            <Text as="span" mt={3} fontSize={15}>Copyright © {new Date().getFullYear()}. All rights reserved.</Text>
        </Flex>      
    </Box>
  )
}

export default Footer