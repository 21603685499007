import React from "react"
import { Flex, Text, Box } from "rebass"
import Grid from "./extend/Grid"
import { minutesFormatter } from "../utils/"

const RecipeInfo = ({info}) => {
    const cookingTime = info.cooking > 60 ?
    minutesFormatter(info.cooking)
    :
    `${info.cooking} mins`

    const prepTime = info.preparation > 60 ?
    `${(info.preparation / 60).toFixed(1)} hrs`
    :
    `${info.preparation} mins`

    const totalTime = info.preparation + info.cooking > 60 ? 
    minutesFormatter(info.preparation + info.cooking)
    :
    `${info.preparation + info.cooking} mins`

    return (
        <Box textAlign="center" py={20} px={2} color="black">
            <Grid gap={0} columns={[4, 1, 2]}>
                <Flex py={10} justifyContent='center' flexDirection='column'>
                    <Text className='uppercase category' letterSpacing={6}>Servings</Text>
                    <Text fontWeight={300} fontSize={16}>{info.serves}</Text>
                </Flex>
                <Flex py={10} justifyContent='center' flexDirection='column'>
                    <Text className='uppercase category' letterSpacing={6}>Prep time</Text>
                    <Text fontWeight={300} fontSize={16}>{prepTime}</Text>
                </Flex>
                <Flex py={10} justifyContent='center' flexDirection='column'>
                    <Text className='uppercase category' letterSpacing={6}>Cooking time</Text>
                    <Text fontWeight={300} fontSize={16}>{cookingTime}</Text>
                </Flex>
                <Flex py={10} justifyContent='center' flexDirection='column'>
                    <Text className='uppercase category' letterSpacing={6}>Total</Text>
                    <Text fontWeight={300} fontSize={16}>{totalTime}</Text>
                </Flex>
            </Grid>
        </Box>
    )
}

export default RecipeInfo 