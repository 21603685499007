const fetchForm = (body) => {
    const url = 'https://21o73fro8c.execute-api.eu-central-1.amazonaws.com/Prod/submitForm';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(body)
        //     name: name,
        //     email: email,
        //     message: message
        // })
    };

    return fetch(url, options)
        .then(response => {
            return response
    });
}

export default fetchForm
