/**
 * Hero component for the main view
 */

import React, { useEffect, useState } from "react"
import { Image, Flex, Text, Box } from "rebass"
import { SubscribeFormVariant } from "@components"
import { optimizeHeroImg } from "../../utils"
import logo from "../../../content/assets/logo-yabuko.svg"

const Hero = () => {

  const [bg, setBg] = useState(null)

  const backgroundObject = bg ? 
  {
    // background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.30) 100%), url('${bg}') no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${bg})`
  }
  : null
  
  useEffect(() => {
    setBg(optimizeHeroImg('https://res.cloudinary.com/yabuko/image/upload/v1590091057/website/89919062_235316857636406_6034402516572045312_n_uanxsk.jpg'))
  }, [])
  return (
    <Flex as="header"
    sx={{
      ...backgroundObject,
    }}
    alignItems='center'
    justifyContent='center'
    flexDirection='column'
    height={'100vh'}
    bg='white'
    >
      <Box
      mt='15px'
      sx={{
        position: 'absolute',
        top: '10px'
      }}
      >          
        <Image src={logo} alt='Yabuko' height='55px'></Image>
      </Box>
      <Flex flexDirection={'column'} alignItems={'center'} width={1}>
        <Text as="span" width={[.9, 'auto', 'auto']} textAlign={'center'} fontSize={[4, 5]} fontWeight={800}>#WEAREFOODMAKERS</Text>

        {/* <Flex flexDirection={'column'} width={[.9, .5, '350px']} lineHeight={.8} color={'#292929'} fontSize={[60, 70, 65]} fontWeight={'800'} mb={.5}>
          <Text as="span" textAlign={'center'}>WE ARE</Text>
          <Text as="span" textAlign={['center', 'left']}>FOOD</Text>
          <Text as="span" textAlign={['center', 'right']}>MAKERS</Text>
        </Flex> */}
        <SubscribeFormVariant variant={'dark'} width={[.9, .6, '400px']}/>
      </Flex>
    </Flex>
  )
}

export default Hero
            