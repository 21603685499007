import React, { Fragment, useEffect, useState } from "react"
import { rhythm } from "../utils/typography"
import { Nav, Main } from "@components"
import { Hero, Footer } from "@sections"

const Layout = ({ location, title, children, posts }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const [isShowing, setShowing] = useState(false)
 
  useEffect(() => {
    const handleScroll = (location, rootPath) => {
      if (location.pathname === rootPath) {
        if (window.pageYOffset > window.innerHeight) setShowing(true)
        else setShowing(false)
      }
    }
    typeof window !== `undefined` ? window.addEventListener("scroll", handleScroll) : window.addEventListener("scroll", handleScroll)
    return () => {
      if (window) window.removeEventListener("scroll", () => handleScroll)
    }
  }, [location, rootPath])  

  return (
    <div>
      { location.pathname === rootPath ? <Hero/> : null }
      <Fragment>
        { location.pathname === rootPath ? <Nav sticky={false} posts={posts}></Nav> : null }
        { isShowing || location.pathname !== rootPath ? <Nav sticky={true} posts={posts}></Nav> : null }
        { location.pathname === rootPath ? <Main posts={posts}/> : null }
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(40),
            padding: `0px ${rhythm(3 / 4)}`,
            paddingTop: '20px'
          }}
        >
          <main>
            {children}
          </main>                  
        </div>
        <Footer/>
      </Fragment>
    </div>
  )
}

export default Layout
