/**
 * Contact Form
 */

import React, { useState } from "react"
import { Flex, Text, Box } from "rebass"
import { Input, Textarea } from '@rebass/forms'
import { AiOutlineLeft } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import fetchForm from '../utils/fetch'

const ContactForm = ({variant, ...rest}) => {
    const [email, setEmail] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [message, setMessage] = useState(undefined)
    const [result, setResult] = useState(undefined)
    const [invalidFields, setInvalidFields] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const _handleSubmit = async (e, email, name, message) => {
        e.preventDefault();
        if (!_handleValidation({ email, name, message }).length) {
            setLoading(true)
            const result = await fetchForm({ name, email, message })
            if (result.status === 200) setResult('Thank you for your message!')
            else setResult('Sorry, there was an error and the message could not be sent')
            setLoading(false)
        }
    }
    const _handleValidation = (fields) => {
        const invalidFields = Object.keys(fields).filter(key => !fields[key])
        setInvalidFields(invalidFields)
        return invalidFields
    }

    const _handleChange = (e) => {        
        const { name, value } = e.target
        switch (name) {
            case 'email': setEmail(value)
                break;
            case 'name': setName(value)
                break;
            case 'message': setMessage(value)
                break;
            default:
                break;
        }
    }
    const _toggleMsg = () => {
        setEmail(undefined)
        setName(undefined)
        setMessage(undefined)
        setResult(undefined)
    }
    return (
        <Flex {...rest} flexDirection='column' id='contact-form' minWidth='100%'>
            {
                result ? 
                <Flex flexDirection='column' alignItems='center'>
                    <Text as="span" textAlign='center' fontSize={30} mb={10}>{result}</Text>
                    <Flex as="button" onClick={_toggleMsg} px={15}><AiOutlineLeft fontSize={20}/></Flex>
                </Flex>
                :
                <Flex as="form" onSubmit={(e) => _handleSubmit(e, email, name, message)} justifyContent='center' flexDirection='column'>
                    <Flex flexDirection={['column', 'row', 'row']}>
                        <Input
                            onChange={_handleChange}
                            id='email'
                            type='email'
                            name='email'
                            placeholder='email'
                        />
                        <Input
                            onChange={_handleChange}
                            id='name'
                            type='text'
                            name='name'
                            placeholder='name'
                            sx={{
                                '@media screen and (max-width: 40em)': {
                                    borderLeft: '1px solid black!important'
                                }
                            }}
                        />
                    </Flex>
                    <Textarea
                        className="message"
                        onChange={_handleChange}
                        id='text'
                        type='text'
                        name='message'
                        placeholder='message'
                        rows={8}
                    />
                    <Flex as="button"
                        justifyContent='center'
                        letterSpacing={3}
                        px={15}
                        disabled={loading}
                    >
                        { loading ? 
                        <ClipLoader size={20} color='inherit'/>
                        :
                        <span>
                            SEND
                        </span>
                        }
                    </Flex>
                    <Box textAlign='center' height={30}>
                        { invalidFields && invalidFields.length ?
                            <Text as="span">Please complete the following fields: { invalidFields.map(field => `${field} `) }</Text>
                            :
                            null
                        }
                        { result ?
                            <Text as="span" className={`subscribe-msg-${variant}`}>{result.msg}</Text>
                            :
                            null             
                        }
                    </Box>
                </Flex>
            }
        </Flex>
    )
}

export default ContactForm