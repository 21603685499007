import React from "react"
import { Card, Text, Flex } from "rebass"
import { Link } from "gatsby"
import { optimizeImg } from "@utils"

const CategoryItem = ({category}) => {
    const optimizedImg = optimizeImg('height', 550, category.image)
    return (
        <Card as='article' width={1} p={2} mx='auto' lineHeight={0}>
            <Flex
                height={400} 
                sx={{
                    backgroundImage: `url(${optimizedImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Link className='uppercase category' to={`categories/${category.route}`}>
                    <Text as="span" color="#545454" bg={'#ffffffcf'} pl={40} pr={30} py={20}>{category.name}</Text>
                </Link>
            </Flex>
        </Card>
    )
}

export default CategoryItem