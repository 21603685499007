/**
 * Card component for posts
 */

import React from "react"
import { Flex, Text, Box } from "rebass"
import { Link } from "gatsby"
import { Separator } from "@components"

const SearchItem = ({post}) => {
    return (
        <Flex p={2} lineHeight={0}>
            <Link to={post.fields.slug}>
                <Box 
                    height={[150]} 
                    width={[100, 150]}
                    sx={{
                        backgroundImage: `url(${post.frontmatter.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Link>
            <Flex flexDirection={'column'} justifyContent={'center'} p={[2, 4]} textAlign='center' lineHeight='normal' color={'black'} height={150} width={[1, 400]}>
                <Link className='item-link capitalize' to={post.fields.slug}>
                    <Box as='header' letterSpacing={3} color='black'><Text as="h3" fontSize={20}>{post.frontmatter.title}</Text></Box>
                </Link>
                <Flex justifyContent='center' py={[2, 3]}>
                    <Separator size='short' color='black'/>
                </Flex>
                <Link className='uppercase category' to={`categories/${post.frontmatter.category}`}>
                    <Text color='#888888' fontSize={[12, 15]}>{post.frontmatter.category}</Text>
                </Link>
            </Flex>
        </Flex>
    )
}

export default SearchItem