import React from "react";
import { Flex } from "rebass"
import { SectionTitle } from "@sections"
import { Grid } from "@extend"
import { MiscItem } from "@components"

const Misc = ({miscPosts}) => {
    return (
        <Flex as="section" flexDirection={'column'}>
        <SectionTitle title={"misc"} to='categories/misc'>
        </SectionTitle>
        <Grid py={2} gap={1} columns={[3, 1, 1]}>        
        {
          miscPosts ? 
          miscPosts.map(({node}) => {
            return (
                <MiscItem key={`${node.id}`} post={node}></MiscItem>
            )
          })
          : null
        }        
        </Grid>
        </Flex>
        );
}

export default Misc
