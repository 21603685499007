import React from "react"
import Grid from "./extend/Grid"
import { Flex, Button } from "rebass"
import { Twitter, Facebook, Mail } from 'react-social-sharing'
import { IoMdPrint } from 'react-icons/io';
import { GrInstagram } from 'react-icons/gr';

const Bio = ({postData}) => {
  const { date, title, path } = postData
  const printThis = () => {
    window.print()
  }
  return (
    <Grid gap={0} columns={[3, 1, 3]}>
      <Flex as="span"
      justifyContent='center'
      flexDirection='column'
      className="uppercase"
      fontSize={15}
      color={'gray'}
      letterSpacing={3}
      sx={{
        '@media screen and (max-width: 40em)': {
          textAlign: 'center',
          marginBottom: '20px',
        }
      }}
      >
        <Flex 
        alignItems='center'
        justifyContent={['center', 'start']}
        p={10}
        >
          <GrInstagram style={{fontSize: '20px', color: '#353535', marginRight: '10px'}}/>
          <a 
          href="https://www.instagram.com/kaja.eliza/"
          style={{
            color: 'black',
            lineHeight: 0,
          }}
          target="_blank"
          rel="noopener noreferrer">
          @kaja.eliza
          </a>
        </Flex>
      </Flex>
      <Flex as="span"
        alignItems='center'
        justifyContent='center'
        className="uppercase"
        fontSize={15}
        color={'#353535'}
        letterSpacing={3}
        sx={{
          '@media screen and (max-width: 40em)': {
            padding: '20px 0',
          }
        }}
      >
        {date}
      </Flex>
      <Flex
      className="social-share-items"
      justifyContent={['center', 'flex-end']}      
      sx={{
        '@media screen and (max-width: 40em)': {
          textAlign: 'center',
        }
      }}
      >
        <Twitter link={path} simple/>
        <Facebook link={path} simple/>
        <Mail link={path} subject={title} simple/>
        <Button aria-label="Print this page" title="Print this page" mx={10} p={10} onClick={printThis} color='#313131' fontSize={20}><IoMdPrint/></Button>
      </Flex>
    </Grid>
  )
}

export default Bio