import React from "react"
import { Link } from "gatsby"
import { Text, Box } from "rebass"

const SectionTitle = ({title, to, children}) => {
    return (
        <Box className="section-title" textAlign="center" py={30} px={2} bg={'#fbfbfb'}>
            { to ? 
            <Link to={to} className="uppercase category">{title}</Link>
            :
            <Text as="span" className="uppercase category">{title}</Text>
            }
            {
                children ?
                    <Box className="instagram-link">
                        {children}
                    </Box>
                    :
                    null
            }
        </Box>
    )
}

export default SectionTitle            
            
            
            