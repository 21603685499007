/**
 * Subscribe button component
 */

import React from "react"
import { Flex, Button } from "rebass"
import { Input } from '@rebass/forms'

const SubscribeForm = ({gap, columns, props, children, ...rest}) => {
    return (
        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Input
                id='name'
                type='text'
                placeholder='Your name'
                py={10}
                mb={10}
                width={[.90, .50]}
            />
            <Input
                id='email'
                type='email'
                placeholder='Your email'
                py={10}
                mb={10}
                width={[.90, .50]}
            />
            <Button
                className="subscribe-navbar"
                fontFamily='Alata'
                letterSpacing={3}
                variant='primary'
                px={20}
                py={9}
            >
                subscribe
            </Button>
        </Flex>
    )
}

export default SubscribeForm