/**
 * Cloudinary Component to optimize format and quality
 */

import React from "react"
import { Image } from "rebass"

const CldImage = ({transformations, children, ...rest}) => {
    const transfString = transformations ? `,${transformations.join()}` : ''
    return (
        <Image
            {...rest}
            src={rest.src.replace('upload/', `upload/f_auto,q_60${transfString}/`)}
        >
            {children}
        </Image>
    )
}

export default CldImage