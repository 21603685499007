import Typography from "typography"
const typography = new Typography({
  fontDisplay: 'swap',
  googleFonts: [
    {
      name: 'Julius Sans One',
      styles: [
        '400'
      ],
    },    
    {
      name: 'EB Garamond',
      styles: [
        '400',
        '500',
        '600'
      ],
    },
    {
      name: 'Nunito Sans',
      styles: [
        '300',
        '400',
        '400i',
        '700',
        '700i',
        '800'
      ],
    },
  ],
  baseFontSize: '18px',
  baseLineHeight: 1.666,
  headerFontFamily: ['EB Garamond', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
  bodyFontFamily: ['Nunito Sans', 'sans-serif'],
  overrideStyles: () => ({
    'p': {
      marginBottom: '20px',
      fontWeight: '400'
    },
    'h1, h2, h3, h4, h5, h6': {
      marginBottom: '20px',
    },
    "a": {
      boxShadow: `none`,
      textDecoration: 'none',
      color: '#313131'
    },
  })
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
