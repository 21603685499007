/**
 * Card component for posts
 */

import React from "react"
import { Flex, Card, Box, Text } from "rebass"
import { Link } from "gatsby"
import { Separator } from "../components"

const PostItem = ({post, ...rest}) => {
    return (
        <Card as='article' p={2} lineHeight={0}>
            <Link to={post.fields.slug} aria-label={rest.label}>
                <Box 
                    height={300} 
                    sx={{
                        backgroundImage: `url(${post.frontmatter.image.replace('upload/', 'upload/c_scale,h_500,f_auto,q_60/')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Link>
            <Flex flexDirection="column" textAlign='center' lineHeight='normal' py={4} color={'black'} height={225}>
                <Link className='item-link capitalize' to={`${post.fields.slug}`} aria-label={post.frontmatter.title}>
                    <Box as='header' px={4} letterSpacing={3} fontSize={25} color='black'><h3>{post.frontmatter.title}</h3></Box>
                </Link>
                <Flex justifyContent='center' pb={15}>
                    <Separator size='short' color='black'/>
                </Flex>
                <Link className='uppercase category' to={`categories/${post.frontmatter.category}`} aria-label={post.frontmatter.category}>
                    <Text as="span" color="black">{post.frontmatter.category}</Text>
                </Link>
            </Flex>
        </Card>
    )
}

export default PostItem